import React from 'react';
import NumberFormat from 'react-number-format';

import { showTokenInShort } from './../../helpers/showTokenInShort';
import CopyIcon from '../../images/copy-sm.svg';

const SelectToken = ({ nextButtonValidate, getValue, formData }) => {

    const nextTab = () => {
        getValue();
    }

    return (
        <div className='tab-box summary-box'>
            <div className="tab-box-inner">
                <h4 className='mb-3'>Summary</h4>
                <hr />

                <div className="d-flex align-items-center justify-content-between">
                    <span>TOKEN NAME</span>
                    <h6 className='text-primary-gradient mb-0'>${formData.symbol}</h6>
                </div>

                <hr />
                <div className="d-flex mb-2 align-items-center justify-content-between">
                    <span>Token Contract</span>

                    <span className='d-inline-flex ms-2 copy-text align-items-center'
                        role="button"
                        onKeyPress={() => { navigator.clipboard.writeText(formData.token) }}
                        tabIndex={0}
                        onClick={() => { navigator.clipboard.writeText(formData.token) }}>{showTokenInShort(formData.token)}
                        <img className='ms-2' src={CopyIcon} alt="copy" /></span>
                </div>

                <div className="d-flex mb-2 align-items-center justify-content-between">
                    <span>Balance</span>

                    <span><NumberFormat displayType="text" thousandSeparator={true} value={formData.supply} /></span>
                </div>

                <div className="d-flex mb-2 align-items-center justify-content-between">
                    <span>Decimals</span>
                    <span>{formData.decimals}</span>
                </div>

                <hr />

                <p className='text-gray text-sm'>Please make sure to verify the accuracy of all the information before proceeding</p>

                <button disabled={!nextButtonValidate} onClick={() => nextTab()} className='w-100 text-white btn btn-primary w-100 btn-lg'>CONTINUE</button>

            </div>
        </div>
    );
};

export default SelectToken;