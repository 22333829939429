import { PINATA_IPFS_API_KEY, PINATA_IPFS_IMAGE_URL, PINATA_IPFS_SECRET_KEY } from "gatsby-env-variables";
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Information from '../components/create/Information';
import SelectToken from '../components/create/SelectToken';
import Summary from '../components/create/Summary';
import Terms from '../components/create/Terms';
import Tokenomics from '../components/create/Tokenomics';
import axios from '../helpers/axios.api';
import { pinFileToIPFS } from '../helpers/pinFileToIPFS';

const form = {
    termsandconditions: {
        branch: ""
    },
    summary: {
        decimals: 0, supply: 0, balance: 0, token: "", symbol: "", name: ""
    },
    token: {
        presale_creator: "", tokenAddress: "",
    },
    tokenomics: {
        tokens_for_presale: "", token_symbol: "MAX", bal_range: 25, hard_cap: "", soft_cap: "", presale_rate: "", percent_towards_liquidity: 25, adjust_listing_rate: 10, min_contribution: "", max_contribution: "", start_date: new Date(), end_date: new Date(), lto_liquidity: "", whitelist: "Yes", baseSymbol: "BUSD"
    },
    information: {
        file: "", project_description: "", website: "", telegram: "", twitter: "", discord: "", instagram: "", facebook: "", medium: "", github: "", referral_address: "", tags: ""
    }
}

const CreateContainer = () => {
    const [tab, setTab] = useState(1);
    const [isNextEnabled, setIsNextEnabled] = useState({ 1: false, 2: false, 3: false, 4: false });
    const [formData, setFormData] = useState(form);

    // useEffect(async () => {
    //     await balanceOf(formData.token.tokenAddress);
    // }, [formData.token.tokenAddress]);

    const getTokenFromMetaMask = useCallback(async () => {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        setFormData({ ...formData, token: { ...formData.token, presale_creator: accounts[0] } });
    }, [setFormData, formData])

    useEffect(() => {
        if (window.ethereum === undefined) {
            toast.error("MetaMask not Installed, Click here to Install it.", { onClick: () => window.open("https://metamask.io/download/", "_blank") });
        } else {
            try {
                getTokenFromMetaMask()
            } catch (error) {
                toast.error(error.message, { onClick: getTokenFromMetaMask });
            }
        }
    }, [getTokenFromMetaMask]);

    const setNextEnabled = (stepIndex, nextEnabled, stepTitle = "", value = {}) => {
        setIsNextEnabled({ ...isNextEnabled, [stepIndex]: nextEnabled });
        setFormData({ ...formData, [stepTitle]: { ...formData[stepTitle], ...value } });
    }

    const handleTab = async (e) => {
        setTab(e)
        if (e === 4 && isNextEnabled[4]) {
            try {
                let body = {
                    enabled: true,
                    owner: "string",
                    type: "IDO",
                    presale: formData.termsandconditions.branch,
                    name: formData.summary.name,
                    description: formData.information.project_description,
                    ticker: formData.summary.symbol,
                    socials: {
                        website: formData.information.website,
                        telegram: formData.information.telegram,
                        twitter: formData.information.twitter,
                        discord: formData.information.discord,
                        instagram: formData.information.instagram,
                        facebook: formData.information.facebook,
                        medium: formData.information.medium,
                        github: formData.information.github,
                    },
                    address: formData.token.presale_creator,
                    liquidity: formData.tokenomics.percent_towards_liquidity,
                    imagePath: "",
                    tokenSupply: formData.tokenomics.tokens_for_presale,
                    minPurchase: formData.tokenomics.min_contribution,
                    maxPurchase: formData.tokenomics.max_contribution,
                    hardCap: formData.tokenomics.hard_cap,
                    softCap: formData.tokenomics.soft_cap,
                    tokenSymbol: formData.summary.symbol,
                    baseSymbol: formData.tokenomics.baseSymbol,
                    status: 0,
                    rate: 0,
                    launchPrice: 0,
                    inversedRate: formData.tokenomics.baseSymbol === "BUSD",
                    startTime: formData.tokenomics.start_date,
                    mocked: false,
                    claimLink: "",
                    kyc: false,
                    audit: false
                }
                const dataResponse = await pinFileToIPFS(formData.information.file, PINATA_IPFS_API_KEY, PINATA_IPFS_SECRET_KEY);
                if (dataResponse.IpfsHash) {
                    body = { ...body, imagePath: `${PINATA_IPFS_IMAGE_URL}${dataResponse.IpfsHash}` }
                }
                
                await axios.post(`/investment`, body, { headers: { 'accept': 'application/json', 'Content-Type': 'application/json' } });

                toast.success("Form submitted successfully");
                setFormData(form);
                setTab(1);
                // navigate("/");
            } catch (error) {
                toast.error("Something goes wrong here, Try again");
            }
        }
    };

    const setSummary = (summary) => {
        setFormData({ ...formData, summary })
    }

    return (
        <Row className='tab-outer'>
            <Col lg={8} className="mb-sm-5 mx-auto mb-4">
                <ul className={`tab-circles progress-${tab} list-unstyled d-flex align-items-center justify-content-between`}>
                    <li>
                        <button onClick={() => handleTab(1)} className={tab > 0 ? 'active' : ""} disabled={tab > 0 ? false : true}><span>1</span></button>
                    </li>
                    <li>
                        <button onClick={() => handleTab(2)} className={tab > 1 ? 'active' : ""} disabled={tab > 1 ? false : true}><span>2</span></button>
                    </li>
                    <li>
                        <button onClick={() => handleTab(3)} className={tab > 2 ? 'active' : ""} disabled={tab > 2 ? false : true}><span>3</span></button>
                    </li>
                    <li>
                        <button onClick={() => handleTab(4)} className={tab > 3 ? 'active' : ""} disabled={tab > 3 ? false : true}><span>4</span></button>
                    </li>
                </ul>
            </Col>

            <div className="w-100 mb-lg-5"></div>

            {tab === 1 ? (
                <Col lg={12} className="mb-4">
                    <Terms nextButtonValidate={isNextEnabled[1]} onNext={setNextEnabled} getValue={() => handleTab(2)} />
                </Col>
            ) : (
                <>
                    <Col lg={8} className="mb-4">
                        {tab === 2 && <SelectToken tabEnabled={isNextEnabled[2]} nextButtonValidate={isNextEnabled[2]} setSummary={setSummary} values={formData["token"]} formData={formData} onNext={setNextEnabled} />}
                        {tab === 3 && <Tokenomics tabEnabled={isNextEnabled[3]} nextButtonValidate={isNextEnabled[3]} values={formData["tokenomics"]} onNext={setNextEnabled} formData={formData} />}
                        {tab === 4 && <Information tabEnabled={isNextEnabled[4]} nextButtonValidate={isNextEnabled[4]} values={formData["information"]} onNext={setNextEnabled} formData={formData} />}
                    </Col>
                    <Col lg={4} className="mb-4">
                        <Summary decimals={"decimals"} formData={formData["summary"]} nextButtonValidate={isNextEnabled[tab]} getValue={() => handleTab(tab + (tab === 4 ? 0 : 1))} />
                    </Col>
                </>
            )}
        </Row>
    );
};

export default CreateContainer;