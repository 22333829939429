import React, { useEffect } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Col, Row } from 'react-bootstrap';

import CopyIcon from '../../images/copy.svg'
import UploadIcon from '../../images/upload-icon.svg'
import { showTokenInShort } from '../../helpers/showTokenInShort';

const InformationFormValidation = Yup.object().shape({
    file: Yup.string().required(),
    project_description: Yup.string().required(),
    website: Yup.string().required()/* .matches(/^((http|https):\/\/)?(.)*$/, "Invalid website url") */,
    telegram: Yup.string().required()/* .matches(/^((http|https):\/\/)?(telegram.)*$/, "Invalid telegram url") */,
    twitter: Yup.string()/* .matches(/^((http|https):\/\/)?(localhost.)*$/, "Invalid twitter url") */,
    discord: Yup.string()/* .matches(/^((http|https):\/\/)?(localhost.)*$/, "Invalid discord url") */,
    instagram: Yup.string()/* .matches(/^((http|https):\/\/)?(localhost.)*$/, "Invalid instagram url") */,
    facebook: Yup.string()/* .matches(/^((http|https):\/\/)?(localhost.)*$/, "Invalid facebook url") */,
    medium: Yup.string()/* .matches(/^((http|https):\/\/)?(localhost.)*$/, "Invalid medium url") */,
    github: Yup.string()/* .matches(/^((http|https):\/\/)?(localhost.)*$/, "Invalid github url") */,
    referral_address: Yup.string(),
    tags: Yup.string()
});

const Information = ({ values, onNext, formData, tabEnabled }) => {

    const formik = useFormik({
        initialValues: values,
        validationSchema: InformationFormValidation,
    });


    useEffect(() => {
        if (!(Object.keys(formik.touched).length === 0 && tabEnabled)) {
            if (Object.keys(formik.errors).length === 0 && Object.keys(formik.touched).length > 0)
                onNext(4, true, "information", formik.values);
            else
                onNext(4, false);
        } else
            onNext(3, true, "tokenomics", formik.values);
    }, [formik, onNext, tabEnabled]);

    return (
        <div className='tab-box'>
            <div className="tab-box-inner tab-box-inner-lg">
                <h3>INFORMATION</h3>

                <hr className='mb-3' />

                <h5 className="mb-3">
                    <span className='text-primary-gradient'> Presale Creator: </span>
                    <span className='d-inline-flex my-1 ms-sm-2 ms-1 copy-text align-items-center'
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => { navigator.clipboard.writeText(formData.token.presale_creator); }}
                        onClick={() => { navigator.clipboard.writeText(formData.token.presale_creator); }}>

                        {showTokenInShort(formData.token.presale_creator)}<img className='ms-sm-3 ms-2' src={CopyIcon} alt="copy" /></span>
                </h5>

                <hr className='mb-4' />

                <Row className='mb-3 justify-content-center'>
                    <Col md="auto" className='mb-3 text-center'>
                        <input type="file" onChange={({ target: { name, files } }) => formik.handleChange({ target: { name, value: files[0] } })} multiple={false} onBlur={formik.handleBlur} name="file" accept="image/*" id='upload' hidden />
                        <label htmlFor='upload' className="mainupload_image mx-auto mb-0">
                            <div className="upload-image">
                                <img src={formik.values.file ? URL.createObjectURL(formik.values.file) : UploadIcon} alt="upload" />
                            </div>
                            <span>Upload Image <br />200x200px</span>
                        </label>
                    </Col>

                    <Col className='mb-3'>
                        <textarea placeholder='Project Description*' value={formik.values.project_description} name="project_description" onChange={formik.handleChange} onBlur={formik.handleBlur} rows="6" className='form-control form-hpay' />
                        {formik.errors.project_description && formik.touched.project_description && <small style={{ color: 'red' }} children={formik.errors.project_description} />}
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.website} name="website" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Website*' className='form-control form-hpay' />
                        {formik.errors.website && formik.touched.website && <small style={{ color: 'red' }} children={formik.errors.website} />}
                    </Col>

                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.telegram} name="telegram" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Telegram*' className='form-control form-hpay' />
                        {formik.errors.telegram && formik.touched.telegram && <small style={{ color: 'red' }} children={formik.errors.telegram} />}
                    </Col>

                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.twitter} name="twitter" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Twitter' className='form-control form-hpay' />
                        {formik.errors.twitter && formik.touched.twitter && <small style={{ color: 'red' }} children={formik.errors.twitter} />}
                    </Col>

                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.discord} name="discord" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Discord' className='form-control form-hpay' />
                        {formik.errors.discord && formik.touched.discord && <small style={{ color: 'red' }} children={formik.errors.discord} />}
                    </Col>

                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.instagram} name="instagram" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Instagram' className='form-control form-hpay' />
                        {formik.errors.instagram && formik.touched.instagram && <small style={{ color: 'red' }} children={formik.errors.instagram} />}
                    </Col>
                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.facebook} name="facebook" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Facebook' className='form-control form-hpay' />
                        {formik.errors.facebook && formik.touched.facebook && <small style={{ color: 'red' }} children={formik.errors.facebook} />}
                    </Col>
                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.medium} name="medium" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Medium' className='form-control form-hpay' />
                        {formik.errors.medium && formik.touched.medium && <small style={{ color: 'red' }} children={formik.errors.medium} />}
                    </Col>
                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.github} name="github" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Github' className='form-control form-hpay' />
                        {formik.errors.github && formik.touched.github && <small style={{ color: 'red' }} children={formik.errors.github} />}
                    </Col>
                    <Col md={6} className="mb-3">
                        <input type="text" value={formik.values.referral_Address} name="referral_Address" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Referral Address' className='form-control form-hpay' />
                        {formik.errors.referral_Address && formik.touched.referral_Address && <small style={{ color: 'red' }} children={formik.errors.referral_Address} />}
                    </Col>
                    <Col md={6} className="mb-3">
                        <select placeholder='#Tags' value={formik.values.tags} name="tags" onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-control form-hpay'>
                            <option value="tag1">#Tags 1</option>
                            <option value="tag2">#Tags 2</option>
                            <option value="tag3">#Tags 3</option>
                        </select>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Information;