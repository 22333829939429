import React, { useEffect } from 'react';
import { Col, Row, InputGroup, FormControl, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import ReactDatePicker from 'react-datepicker';
import * as Yup from 'yup';

import CopyIcon from '../../images/copy.svg'
import RangeSlider from './RangeSlider';
import { showTokenInShort } from '../../helpers/showTokenInShort';


const TokenomicsFormValidation = (formData) => Yup.object().shape({
    tokens_for_presale: Yup.number().typeError("Tokens for presale is must a number").max(formData.summary.balance, `token for presale is less then ${formData.summary.balance}`).required("token for presale is a required"),
    token_symbol: Yup.string().oneOf(["TOKEN", "MAX"]).default("TOKEN").required("token symbol is a required"),
    bal_range: Yup.number().typeError("balance range is must a number").required("balance range is a required"),
    hard_cap: Yup.number().typeError("hard capital is must a number").required("hard capital is a required"),
    soft_cap: Yup.number().typeError("soft capital is must a number").required("soft capital is a required"),
    presale_rate: Yup.number().typeError("Presale rate is must a number").required("Presale rate is required field"),
    percent_towards_liquidity: Yup.number().typeError("Percent towards liquidity is must a number").required(),
    adjust_listing_rate: Yup.number().typeError("Adjust listing rate is must a number").required(),
    min_contribution: Yup.number().typeError("Minimum contribution is must a number").required("min contribution is a required"),
    max_contribution: Yup.number().typeError("Maximum contribution is must a number").required("max contribution is a required"),
    start_date: Yup.mixed().typeError("Start date is must a date").required("start date is a required").test("is-date", "start date is not from past", (value) => new Date(value) > new Date()),
    end_date: Yup.mixed().typeError("End date is must a date").required("end date is a required").test("is-date", "end date is not from past", (value) => new Date(value) > new Date()),
    ito_liquidity: Yup.string().required("Ito Liquidity is a required"),
    whitelist: Yup.string().required("whitelist is a required"),
    baseSymbol: Yup.string().required("Please select baseSymbol is a required")
});

const Tokenomics = ({ values, onNext, formData, tabEnabled }) => {
    const formik = useFormik({
        initialValues: values,
        validationSchema: TokenomicsFormValidation(formData),
    });

    useEffect(() => {
        if (!(Object.keys(formik.touched).length === 0 && tabEnabled)) {
         
            if (Object.keys(formik.errors).length === 0 && Object.keys(formik.touched).length > 0) {
                onNext(3, true, "tokenomics", formik.values);
            } else {
                onNext(3, false);
            }

        } else {
            onNext(3, true, "tokenomics", formik.values);
        }
    }, [formik, onNext, tabEnabled]);

    const onCheckTokenPresale = (e) => {
        formik.handleChange(e);
        formik.handleChange({ target: { value: formData.summary.balance * e.target.value / 100, name: "tokens_for_presale" } })
    }

    return (
        <div className='tab-box'>
            <div className="tab-box-inner tab-box-inner-lg">
                <h3>TOKENOMICS</h3>
                <hr className='mb-3' />
                <h5 className="mb-3">
                    <span className='text-primary-gradient'>Presale Creator:</span>
                    <span className='d-inline-flex my-1 ms-sm-2 ms-1 copy-text align-items-center'
                        role="button" onKeyPress={() => { navigator.clipboard.writeText(formData.token.presale_creator); }}
                        tabIndex={0}
                        onClick={() => { navigator.clipboard.writeText(formData.token.presale_creator); }}>
                        {showTokenInShort(formData.token.presale_creator)}<img className='ms-sm-3 ms-2' src={CopyIcon} alt="copy" /></span>
                </h5>
                <hr className='mb-4' />

                <div className="form-hpay-group mb-3">
                    <input type="text" name={"tokens_for_presale"} value={formik.values.tokens_for_presale} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='# Tokens For Presale*' style={{ paddingRight: '150px' }} className='form-control form-hpay' />
                    {formik.errors.tokens_for_presale && formik.touched.tokens_for_presale && <small style={{ color: 'red' }} children={formik.errors.tokens_for_presale} />}
                    <div className="form-hpay-toggle d-flex align-items-center">
                        <label htmlFor="hpay-token">
                            <input type="radio" readOnly={true} id="hpay-token" value={"TOKEN"} name='token_symbol' hidden checked={formik.values.token_symbol === "TOKEN"} />
                            <span>TOKEN</span>
                        </label>
                        <label htmlFor="hpay-max">
                            <input type="radio" readOnly={true} id="hpay-max" value={"MAX"} name='token_symbol' hidden checked={formik.values.token_symbol === "MAX"} />
                            <span>MAX</span>
                        </label>
                    </div>
                </div>

                <Row className="align-items-center mb-4 radio-percent">
                    <Col md={6}>
                        <div className="d-flex text-center w-100 align-items-center">
                            <label>
                                <input type="radio" name="bal_range" value={25} checked={+formik.values.bal_range === 25} onChange={onCheckTokenPresale} onBlur={formik.handleBlur} hidden />
                                <span>25 %</span>
                            </label>
                            <label>
                                <input type="radio" name="bal_range" value={50} checked={+formik.values.bal_range === 50} onChange={onCheckTokenPresale} onBlur={formik.handleBlur} hidden />
                                <span>50 %</span>
                            </label>
                            <label>
                                <input type="radio" name="bal_range" value={75} checked={+formik.values.bal_range === 75} onChange={onCheckTokenPresale} onBlur={formik.handleBlur} hidden />
                                <span>75 %</span>
                            </label>
                            <label>
                                <input type="radio" name="bal_range" value={100} checked={+formik.values.bal_range === 100} onChange={onCheckTokenPresale} onBlur={formik.handleBlur} hidden />
                                <span>100 %</span>
                            </label>
                        </div>


                    </Col>
                    <Col md={6}>
                        <div className="text-md-end d-flex justify-content-end mt-md-0 mt-2">
                            <span className='text-primary-gradient'>Balance:</span>{formData.summary.balance}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={4} className="mb-md-0 mb-3">
                        <InputGroup className='form-hpay-input-group'>
                            <div className='d-flex'>
                                <FormControl placeholder="Hard Cap*" value={formik.values.hard_cap} name="hard_cap" onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-hpay' />
                                <InputGroup.Text className='border-0'><span className='text-primary-gradient'>{formik.values.baseSymbol}</span></InputGroup.Text>
                            </div>
                            {formik.errors.hard_cap && formik.touched.hard_cap && <small style={{ color: 'red' }} children={formik.errors.hard_cap} />}
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        <InputGroup className='form-hpay-input-group'>
                            <div className='d-flex'>
                                <FormControl placeholder="Soft Cap*" value={formik.values.soft_cap} name="soft_cap" onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-hpay' />
                                <InputGroup.Text className='border-0'><span className='text-primary-gradient'>{formik.values.baseSymbol}</span></InputGroup.Text>
                            </div>
                            {formik.errors.soft_cap && formik.touched.soft_cap && <small style={{ color: 'red' }} children={formik.errors.soft_cap} />}
                        </InputGroup>
                    </Col>
                    <Col md={4} className="mb-md-0 mb-3">
                        <InputGroup className='form-hpay-input-group'>
                            <div className='d-flex w-100'>

                                <Form.Select className='form-hpay w-100' name="baseSymbol" value={formik.values.baseSymbol} onChange={formik.handleChange} onBlur={formik.handleBlur}  >
                                    <option value={"BUSD"}> BUSD </option>
                                    <option value={"BNB"}  >BNB </option>
                                </Form.Select>

                                {/* <Form.Select aria-label="Default select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select> */}
                                {/* <FormControl placeholder="Soft Cap*" value={formik.values.soft_cap} name="soft_cap" onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-hpay' /> */}
                                {/* <InputGroup.Text className='border-0'><span className='text-primary-gradient'>baseSymbol</span></InputGroup.Text> */}
                            </div>
                        </InputGroup>
                    </Col>
                </Row>

                <Row className='text-center radio-percent'>
                    <Col md={6} className="pt-4 mb-md-5 mb-4">
                        <div className='text-start'>
                            <h6>PRESALE RATE</h6>
                            <FormControl placeholder="Presale Rate*" name={"presale_rate"} value={formik.values.presale_rate} onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-hpay' />
                            {formik.errors.presale_rate && formik.touched.presale_rate && <small style={{ color: 'red' }} children={formik.errors.presale_rate} />}
                        </div>
                        <h6>PERCENT TOWARDS LIQUIDITY</h6>

                        <RangeSlider name="percent_towards_liquidity" value={[formik.values.percent_towards_liquidity || 0]} onChange={(value) => formik.handleChange({ target: { name: "percent_towards_liquidity", value: value[0] } })} />
                        {formik.errors.percent_towards_liquidity && formik.touched.percent_towards_liquidity && <small style={{ color: 'red' }} children={formik.errors.percent_towards_liquidity} />}
                    </Col>
                    <Col md={6} className="mb-5 pt-4">
                        <div className='mb-3'>
                            <h6>LISTTING RATE</h6>
                            <p>1 <span className='text-primary-gradient'>BNB = </span>
                                <NumberFormat displayType={'text'} thousandSeparator={true} value={((formik.values.presale_rate * formik.values.percent_towards_liquidity) / 100) + ((formik.values.presale_rate * formik.values.adjust_listing_rate) / 100)} /> <span className='text-primary-gradient'>HPAY</span></p>
                        </div>
                        <h6>ADJUST LISTING RATE</h6>


                        <div className="d-flex text-center w-100 mt-2 align-items-center">
                            <label>
                                <input type="radio" name="adjust_listing_rate" value={0} checked={+formik.values.adjust_listing_rate === 0} onChange={formik.handleChange} onBlur={formik.handleBlur} hidden />
                                <span>-0 %</span>
                            </label>
                            <label>
                                <input type="radio" name="adjust_listing_rate" value={10} checked={+formik.values.adjust_listing_rate === 10} onChange={formik.handleChange} onBlur={formik.handleBlur} hidden />
                                <span>-10 %</span>
                            </label>
                            <label>
                                <input type="radio" name="adjust_listing_rate" value={15} checked={+formik.values.adjust_listing_rate === 15} onChange={formik.handleChange} onBlur={formik.handleBlur} hidden />
                                <span>-15 %</span>
                            </label>
                            <label>
                                <input type="radio" name="adjust_listing_rate" value={20} checked={+formik.values.adjust_listing_rate === 20} onChange={formik.handleChange} onBlur={formik.handleBlur} hidden />
                                <span>-20 %</span>
                            </label>
                            <label>
                                <input type="radio" name="adjust_listing_rate" value={25} checked={+formik.values.adjust_listing_rate === 25} onChange={formik.handleChange} onBlur={formik.handleBlur} hidden />
                                <span>-25 %</span>
                            </label>
                        </div>
                        {formik.errors.adjust_listing_rate && formik.touched.adjust_listing_rate && <small style={{ color: 'red' }} children={formik.errors.adjust_listing_rate} />}
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="mb-3">
                        <InputGroup className='form-hpay-input-group'>
                            <div className='d-flex'>
                                <FormControl placeholder="Minimum Contribution*" name={"min_contribution"} value={formik.values.min_contribution} onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-hpay' />
                                <InputGroup.Text className='border-0'><span className='text-primary-gradient'>{formik.values.baseSymbol}</span></InputGroup.Text>
                            </div>
                            {formik.errors.min_contribution && formik.touched.min_contribution && <small style={{ color: 'red' }} children={formik.errors.min_contribution} />}
                        </InputGroup>
                    </Col>
                    <Col md={6} className="mb-3">
                        <InputGroup className='form-hpay-input-group'>
                            <div className='d-flex'>
                                <FormControl placeholder="Maximum Contribution" name={"max_contribution"} value={formik.values.max_contribution} onChange={formik.handleChange} onBlur={formik.handleBlur} className='form-hpay' />
                                <InputGroup.Text className='border-0'><span className='text-primary-gradient'>{formik.values.baseSymbol}</span></InputGroup.Text>
                            </div>
                            {formik.errors.max_contribution && formik.touched.max_contribution && <small style={{ color: 'red' }} children={formik.errors.max_contribution} />}
                        </InputGroup>
                    </Col>
                    <Col md={6} className="mb-3">
                        <ReactDatePicker className='dateandTimePicker' timeInputLabel="Time: " minDate={new Date()} minTime={new Date()} dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput selected={formik.values.start_date} onChange={(date) => formik.handleChange({ target: { name: "start_date", value: date } })} />
                        {formik.errors.start_date && <small style={{ color: 'red' }} children={formik.errors.start_date} />}
                    </Col>
                    <Col md={6} className="mb-3">
                        <ReactDatePicker timeInputLabel="Time: " className='dateandTimePicker' minDate={new Date()} minTime={new Date()} dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput selected={formik.values.end_date} onChange={(date) => formik.handleChange({ target: { name: "end_date", value: date } })} />
                        {formik.errors.end_date && <small style={{ color: 'red' }} children={formik.errors.end_date} />}
                    </Col>
                    <Col md={12} className="mb-3">
                        <InputGroup className='form-hpay-input-group'>
                            <FormControl value={formik.values.ito_liquidity} name="ito_liquidity" onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Itto liquidity loc" className='form-hpay' />
                            <InputGroup.Text className='border-0'><span className='text-primary-gradient'>Days</span></InputGroup.Text>
                        </InputGroup>
                        {formik.errors.ito_liquidity && formik.touched.ito_liquidity && <small style={{ color: 'red' }} children={formik.errors.ito_liquidity} />}
                    </Col>
                    <Col md={12} className="mb-3">
                        <div className="form-hpay-group">
                            <input type="text" readOnly onBlur={formik.handleBlur} placeholder='Whitelist?' style={{ paddingRight: '150px' }} className='form-control form-hpay' />
                            {formik.errors.whitelist && formik.touched.whitelist && <small style={{ color: 'red' }} children={formik.errors.whitelist} />}
                            <div className="form-hpay-toggle d-flex align-items-center">
                                <label htmlFor="hpay-whitelist-no">
                                    <input type="radio" id="hpay-whitelist-no" name='whitelist' value={"No"} checked={formik.values.whitelist === "No"} onChange={formik.handleChange} hidden />
                                    <span>No</span>
                                </label>
                                <label htmlFor="hpay-whitelist-yes">
                                    <input type="radio" id="hpay-whitelist-yes" name='whitelist' value={"Yes"} checked={formik.values.whitelist === "Yes"} onChange={formik.handleChange} hidden />
                                    <span>Yes</span>
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Tokenomics;