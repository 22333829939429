import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { showTokenInShort } from '../../helpers/showTokenInShort';
import CopyIcon from '../../images/copy.svg'
import { useTokenContract } from '../../web3/contracts/contracts';

const SelectToken = ({ onNext, values, setSummary, formData }) => {

    const [token, setToken] = useState({ value: values.tokenAddress, validate: new RegExp(/^0x[a-fA-F0-9]{40}$/), isValid: true });
    const [metamastToken,] = useState({ token: token.value });
    const { decimals, supply, balanceOf, symbol, isValidToken, name, addressToken } = useTokenContract(token.value);

    const onChangeToken = ({ target: { value } }) => {
        setToken({ ...token, value, isValid: !value ? true : !!token.validate.test(value) });
    }

    useEffect(() => {
        const fn = async () => {
            onNext(2, (token.isValid && token.value && isValidToken), "token", { presale_creator: values.presale_creator, tokenAddress: token.value });
        }
        fn()
    }, [token, isValidToken, onNext, values]);

    useEffect(() => {
        const fn = async () => {
            setSummary({ decimals, supply, balance: !isValidToken ? 0 : await balanceOf(formData.token?.presale_creator), token: values.tokenAddress, symbol, name });
        }
        fn();
    }, [decimals, isValidToken, supply, addressToken, setSummary, formData, balanceOf, symbol, name, values]);

    return (
        <div className='tab-box'>
            <div className="tab-box-inner tab-box-inner-lg">
                <h3>Select Token</h3>

                <hr className='mb-3' />

                <h5 className="mb-3">
                    <span className='text-primary-gradient'>Presale Creator:</span>
                    <span className='d-inline-flex my-1 ms-sm-2 ms-1 copy-text align-items-center'
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => navigator.clipboard.writeText(values.presale_creator)}
                        onClick={() => navigator.clipboard.writeText(values.presale_creator)}>
                        {showTokenInShort(values.presale_creator)} <img className='ms-sm-3 ms-2' src={CopyIcon} alt="copy" />
                    </span>
                </h5>

                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>
                <input value={token.value} onChange={onChangeToken} type="text" placeholder='Enter Token Address' className='form-control form-hpay' />
                {(!token.isValid) && <small style={{ color: 'red' }}>Invalid token address</small>}

                <div className="divider position-relative py-3">
                    <span>OR</span>
                    <hr />
                </div>

                <div className="mb-4 card-token">
                    <div className="card-token-heading"><h6 className='m-0'>Your Tokens (1)</h6></div>
                    <div className="card-token-body">
                        <div className="card-token-item">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <span>TOKEN NAME</span>
                                <span>{metamastToken.token}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <p style={{ color: '#001312' }} className='m-0'>${symbol}</p>
                                <span><span style={{ color: '#001312' }}>Balance:</span> <NumberFormat displayType="text" thousandSeparator={true} value={formData.summary.balance} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>
            </div>
        </div>
    );
};

export default SelectToken;