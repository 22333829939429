import axios from "axios";

export const pinFileToIPFS = async (file, pinataApiKey, pinataSecretApiKey) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    let data = new FormData();
    data.append('file', file);

    const metadata = JSON.stringify({
        name: `${Date.now()}_${file.name}`,
    });
    data.append('pinataMetadata', metadata);

    data.append('pinataOptions', JSON.stringify({ cidVersion: 0, customPinPolicy: { regions: [{ id: 'FRA1', desiredReplicationCount: 1 }, { id: 'NYC1', desiredReplicationCount: 0 }] } }));

    const response = await axios.post(url, data, { maxBodyLength: 'Infinity', headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}`, pinata_api_key: pinataApiKey, pinata_secret_api_key: pinataSecretApiKey } });
    return response;
};