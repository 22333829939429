import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import timer from "../../../static/time.svg";
import vector from "../../../static/vector.svg";

const Terms = ({ getValue, onNext }) => {
    const [position, setPosition] = useState(false);
    const [branch, setBranch] = useState("presale");
    // The scroll listener
    useEffect(() => {
        onNext(1, position, "termsandconditions", { branch });
    }, [position, branch, onNext])

    const nextTab = () => {
        getValue();
    }

    const onChangeCheckbox = ({ target: { checked } }) => setPosition(checked);

    return (
        <Row className="mb-2 justify-content-center">
            <Col md={12} lg={9} className="mb-md-5 mb-4">
                <div className='d-flex justify-content-center btn-presalesect mb-5'>
                    <button onClick={() => setBranch("presale")} className={`button mx-3 py-4 text-white ${branch === "presale" ? "btn-primary" : "btn-default text-gray"}`}>
                        <img src={timer} alt="timer" />
                        <h4 className='mb-0 mt-4'>PRESALE</h4>
                    </button>
                    <button onClick={() => setBranch("fairlaunch")} className={`button mx-3 py-4 ${branch === "fairlaunch" ? "btn-primary" : "btn-default text-gray"}`}>
                        <img src={vector} alt="timer" />
                        <h4 className='mb-0 mt-4'>FAIRLAUNCH</h4>
                    </button>
                </div>
                <div className="text-center px-md-4 mb-4">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>
                </div>

                <div className="terms-area">
                    <h6>Terms & Conditions</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>

                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>

                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>

                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                    </ul>
                    <div className='d-flex justify-content-center' style={{ alignItems: "center" }}>
                        <input className='mx-2' type={"checkbox"} onChange={onChangeCheckbox} />
                        <p className='d-flex' style={{ alignItems: "center", lineHeight: 1 }}>I have read and agree with the terms and conditions</p>
                    </div>
                </div>

                <button type='button' disabled={position ? false : true} checked={position} onClick={() => nextTab()} className='w-100 text-white btn btn-primary mb-4 w-100 btn-lg'>CREATE ILO</button>

                <div className="text-center px-md-4 mb-4">
                    <p>By clicking on “Create ILO” you hereby agree and comply with ShieldPad's terms and conditions of service.</p>
                </div>
            </Col>
        </Row>
    );
};

export default Terms;