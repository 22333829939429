import React from "react";
import { Range } from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 100;

class RangeSlider extends React.Component {

  render() {
    return (
      <div className="range-slider">
        <Range values={this.props.value} step={this.props.STEP || STEP} min={this.props.MIN || MIN} max={this.props.MAX || MAX} onChange={this.props.onChange}
          renderTrack={({ props, children }) => (
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => { }}
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "100%" }}>
              <div className="range-strip" style={{ width: this.props.value + '%' }}></div>
              <div ref={props.ref} style={{ height: "4px", width: "100%", borderRadius: "9px", alignSelf: "center" }}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} style={{ ...props.style, height: "13px", width: "13px", borderRadius: "13px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }} />
          )}
        />
        <output style={{ left: (this.props.value > 93 ? 93 : this.props.value) + '%' }} id="output">
          {this.props.value[0] + '%'}
        </output>
      </div>
    );
  }
}

export default RangeSlider;